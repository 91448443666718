import classnames from 'classnames'
import { useCallback } from 'react'

import { colors } from '../../../../shared/constants'

import LinkChangeQuery from '../../../../shared/router/link-change-query'

import Multicolor from '../../../../../svg/multicolor.svg'

import '../../../../../scss/react/filter/color.scss'

const colorToStyle = (color) => colors.find(({ value }) => value === color)

const ColorFilter = ({
    buckets,
    aggregationKey,
    filterKey,
    formatLabel,
    value,
}) => {
    const addValue = useCallback(
        (toAdd) => {
            if (!value) return [toAdd]

            const values = value.split(',')

            if (values.includes(toAdd)) {
                return values
            }
            return [...values, toAdd]
        },
        [value]
    )

    return (
        <div className="color-filter">
            {buckets.map((bucket) => (
                <LinkChangeQuery
                    key={`${filterKey}-${bucket.key}`}
                    query={{
                        [filterKey]: addValue(bucket.key).join(','),
                    }}
                >
                    <span
                        className={classnames('color', bucket.key)}
                        style={colorToStyle(bucket.key)}
                    >
                        {bucket.key === 'multicolor' && <Multicolor />}
                    </span>
                    <span>
                        {formatLabel && formatLabel(bucket.key)}
                        {!formatLabel && bucket.key}{' '}
                        <small>
                            {'('}
                            {bucket[`reverse.${aggregationKey}`].doc_count}
                            {`)`}
                        </small>
                    </span>
                </LinkChangeQuery>
            ))}
        </div>
    )
}

export default ColorFilter
