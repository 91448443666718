import { useCallback, useMemo } from 'react'
import Trans from '../../../../shared/components/trans'
import FilterBox from '../../../../shared/filters/box'
import SelectItem from '../../../../shared/filters/select-item'

const StockFilters = ({ query }) => {
    const stock = useMemo(
        () =>
            query?.stock?.split(',') || [
                'in_stock',
                'call_for_stock',
                'not_in_stock',
            ],
        [query?.stock]
    )

    const showProductsWithoutImages = useMemo(
        () => query?.['images'] === 'all',
        [query]
    )

    const remove = useCallback(
        (key: string) => {
            return stock.filter((option) => option !== key).join(',')
        },
        [stock]
    )

    const add = useCallback(
        (key: string) => {
            return [...stock, key].join(',')
        },
        [stock]
    )

    return (
        <FilterBox header="Toon producten">
            <div className="multiselect-filter">
                <ul>
                    <SelectItem
                        query={{
                            stock: stock.includes('in_stock')
                                ? remove('in_stock')
                                : add('in_stock'),
                        }}
                        name="stock"
                        checked={stock.includes('in_stock')}
                    >
                        <Trans label="In stock" />
                    </SelectItem>
                    <SelectItem
                        query={{
                            stock: stock.includes('call_for_stock')
                                ? remove('call_for_stock')
                                : add('call_for_stock'),
                        }}
                        name="stock"
                        checked={stock.includes('call_for_stock')}
                    >
                        <Trans label="Voorraad niet bekend" />
                    </SelectItem>
                    <SelectItem
                        query={{
                            stock: stock.includes('not_in_stock')
                                ? remove('not_in_stock')
                                : add('not_in_stock'),
                        }}
                        name="stock"
                        checked={stock.includes('not_in_stock')}
                    >
                        <Trans label="Niet op voorraad" />
                    </SelectItem>
                    <SelectItem
                        query={{
                            images: showProductsWithoutImages ? null : 'all',
                        }}
                        name="stock"
                        checked={query.images === 'all'}
                    >
                        <Trans label="Zonder afbeeldingen" />
                    </SelectItem>
                </ul>
            </div>
        </FilterBox>
    )
}

export default StockFilters
