import Link from 'next/link'
import React, { useContext, useMemo } from 'react'

import RouteContext from '../../context/route'

export interface LinkChangeQueryProps {
    className?: string
    children?: React.ReactNode
    style?: React.CSSProperties
    onClick?: () => void
    query?: Record<string, any>
    href?: string
    replace?: boolean
    scroll?: boolean
}

const LinkChangeQuery = ({
    className,
    children,
    style,
    onClick,
    query,
    href,
    replace,
    scroll,
}: LinkChangeQueryProps) => {
    const { changeQuery } = useContext(RouteContext)
    const route = changeQuery(query)
    const shouldScroll = typeof scroll === 'boolean' ? scroll : !replace

    const newHref = useMemo(() => {
        if (href && !query) return href

        return {
            ...route,
            pathname: href ?? route.pathname,
        }
    }, [href, query, route])

    return (
        <Link replace={replace} scroll={shouldScroll} href={newHref}>
            <a className={className} style={style} onClick={onClick}>
                {children}
            </a>
        </Link>
    )
}

export default LinkChangeQuery
