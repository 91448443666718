import { AggregationBucket } from '@zupr/types/fo'
import { useCallback, useMemo } from 'react'

import SelectItem from '../../../../shared/filters/select-item'

interface FilterMultiselectProps {
    buckets: AggregationBucket[]
    aggregationKey: string
    filterKey: string
    value: string
    formatLabel?: (value: any) => string
    labels?: string[]
}

const FilterMultiselect = ({
    buckets,
    aggregationKey,
    filterKey,
    value,
    formatLabel,
    labels,
}: FilterMultiselectProps) => {
    const values = useMemo(() => {
        return (value && `${value}`.split(',')) || []
    }, [value])

    // if no alternative labels are passed
    // the keys themselves are the labels
    if (!labels) {
        labels = buckets.map(({ key }) => key)
    }

    const toggleValue = useCallback(
        (toToggle) => {
            if (values.includes(toToggle)) {
                return values.filter((val) => val !== toToggle)
            }

            return [...values, toToggle]
        },
        [values]
    )

    const count = useCallback(
        (bucket) => {
            if (bucket[`reverse.${aggregationKey}`])
                return bucket[`reverse.${aggregationKey}`].doc_count
            return bucket.doc_count
        },
        [aggregationKey]
    )

    if (!buckets.length) return null

    return (
        <div className="multiselect-filter">
            <ul>
                {buckets.map((bucket) => (
                    <SelectItem
                        key={`${filterKey}-${bucket.key}`}
                        query={{
                            [filterKey]: toggleValue(bucket.key).join(','),
                        }}
                        count={count(bucket)}
                        name={filterKey}
                        checked={values.includes(bucket.key)}
                    >
                        {formatLabel && formatLabel(bucket.key)}
                        {!formatLabel && bucket.key}
                    </SelectItem>
                ))}
            </ul>
        </div>
    )
}

export default FilterMultiselect
