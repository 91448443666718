import classnames from 'classnames'
import { useState } from 'react'

import Trans from '../components/trans'
import { FilterBox, Props as FilterBoxProps } from './box'

import Triangle from '../../../svg/triangle.svg'

interface FilterCollapseProps extends FilterBoxProps {
    startCollapsed?: boolean
}

const FilterCollapse = ({
    children,
    header,
    startCollapsed,
}: FilterCollapseProps) => {
    const [collapsed, setCollapsed] = useState<boolean>()

    const isCollapsed =
        typeof collapsed === 'boolean' ? collapsed : !!startCollapsed

    return (
        <FilterBox
            className={classnames({
                'filter-box-collapsed': isCollapsed,
            })}
            header={
                <button onClick={() => setCollapsed(!isCollapsed)}>
                    {header && typeof header === 'string' && (
                        <Trans label={header} />
                    )}
                    {header && typeof header !== 'string' && header}
                    <Triangle />
                </button>
            }
        >
            {!isCollapsed && children}
        </FilterBox>
    )
}

export default FilterCollapse
