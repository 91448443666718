import { t } from '@zupr/i18n'
import classNames from 'classnames'
import { useCallback, useState } from 'react'

import ModalDialog from '../components/dialog'

import '../../../scss/figma/filter-sheet.scss'

import CloseIcon from '../../../svg/cross.svg'
import FilterIcon from '../../../svg/filter.svg'

interface FilterButtonProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    filterCount?: number
    label?: string
    onClear?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    className?: string
}

export const FilterButton = ({
    setOpen,
    filterCount,
    label,
    className,
}: FilterButtonProps) => {
    const handleClick = useCallback(() => setOpen((open) => !open), [setOpen])

    return (
        <button
            onClick={handleClick}
            className={classNames('btn btn-radius-small', className)}
        >
            <FilterIcon />
            <span>
                {!!filterCount &&
                    t('1 filter |||| %{smart_count} filters', {
                        smart_count: filterCount,
                    })}
                {!filterCount && t(label || 'Filteren')}
            </span>
        </button>
    )
}

interface Props {
    children: React.ReactNode
    filterCount?: number
    resultCount?: number | null
    buttonLabel?: string
    buttonClass?: string
}

const FilterSheet = ({
    children,
    filterCount,
    resultCount,
    buttonLabel,
    buttonClass,
}: Props) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <FilterButton
                open={open}
                setOpen={setOpen}
                filterCount={filterCount}
                label={buttonLabel}
                className={buttonClass}
            />
            <ModalDialog
                position="right"
                isOpen={open}
                onClose={() => setOpen(false)}
            >
                <div className="filter-sheet search-filters">
                    <div className="filter-sheet-header">
                        <h4>{t('Filter')}</h4>
                        <button
                            className="btn btn-micro btn-light"
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="filter-sheet-content">{children}</div>
                    <div className="filter-sheet-footer">
                        <button
                            className="btn btn-success"
                            onClick={() => setOpen(false)}
                        >
                            {typeof resultCount !== 'number'
                                ? t('Opslaan')
                                : t(
                                      'Filter %{smart_count} result |||| Filter %{smart_count} results',
                                      { smart_count: resultCount }
                                  )}
                        </button>
                    </div>
                </div>
            </ModalDialog>
        </>
    )
}

export default FilterSheet
