import React from 'react'

import { useIsMobile } from '../../../../context/ux'
import FilterSheet from '../../../../shared/filters/sheet'

interface FilterContainer {
    renderFilters: React.ReactNode
    filterCount: number
    resultCount: number
}

const Filters = ({
    renderFilters,
    filterCount,
    resultCount,
}: FilterContainer) => {
    const isMobile = useIsMobile()
    return (
        <React.Fragment>
            {isMobile && (
                <FilterSheet
                    buttonLabel="Filteren & Sorteren"
                    buttonClass="btn-small"
                    filterCount={filterCount}
                    resultCount={resultCount}
                >
                    {renderFilters}
                </FilterSheet>
            )}
            {!isMobile && <div className="search-filters">{renderFilters}</div>}
        </React.Fragment>
    )
}

export default Filters
