import { t } from '@zupr/i18n'
import {
    Location,
    Product,
    ProductImage,
    ProductLocation,
} from '@zupr/types/fo'
import { ThemeTypeEnum } from '@zupr/types/graphql'
import { formatSubtitle } from '@zupr/utils/product'
import { productLocationUrl } from '@zupr/utils/url'
import Link from 'next/link'
import { useCallback, useContext } from 'react'

import ModalContext from '../../../../context/modal'
import RouteContext from '../../../../context/route'
import Badge from '../../../../shared/components/badge'
import ReadMore from '../../../../shared/components/read-more'
import Trans from '../../../../shared/components/trans'
import PriceAndUnit from '../../../../shared/product-location/price-and-unit'
import Discount from '../../../../shared/product/discount'
import ProductLocationModal from '../../product-location/modal'

import '../../../../../scss/react/frontoffice/block/location-product.scss'

import ShoppingListIcon from '../../../../../svg/shopping-cart.svg'
import { ImgWithFallback } from '../../../../shared/components/image'

interface ProductLocationItemImageProps {
    image?: ProductImage
    title: string
    theme?: ThemeTypeEnum
    pricing?: {
        price: number
        original_price: number
    }
    index: number
    showIndexBadge?: boolean
}

const ProductLocationItemImage = ({
    image,
    title,
    theme,
    pricing,
    index,
    showIndexBadge,
}: ProductLocationItemImageProps) => (
    <ImgWithFallback image={image} alt={title} eager={index < 6}>
        {(theme || pricing || showIndexBadge) && (
            <div className="badges">
                {showIndexBadge && (
                    <Badge dark>
                        {'#'}
                        {index + 1}
                    </Badge>
                )}
                {theme && (
                    <Badge className={`badge-${theme}`}>
                        {t(theme, 'themes')}
                    </Badge>
                )}
                {pricing && (
                    <Badge danger>
                        <Discount
                            price={pricing.price}
                            originalPrice={pricing.original_price}
                        />
                    </Badge>
                )}
            </div>
        )}
    </ImgWithFallback>
)

interface ProductLocationItemTitlesProps {
    title: string
    subtitle: string
    variationCount?: number
    link?: string
}

const ProductLocationItemTitles = ({
    link,
    title,
    subtitle,
    variationCount,
}: ProductLocationItemTitlesProps) => (
    <div className="titles">
        <h2>
            {link && <Link href={link}>{title}</Link>}
            {!link && title}
        </h2>
        <h3>{subtitle}</h3>
        {variationCount && (
            <strong>
                {`+ `}
                <Trans
                    label="product.variations"
                    values={{
                        smart_count: variationCount,
                    }}
                />
            </strong>
        )}
    </div>
)

interface ProductAndLocationProps {
    variation: Product
    theme?: ThemeTypeEnum
    location: Location
    index: number
}

export const ProductAndLocation = ({
    variation,
    theme,
    location,
    index,
}: ProductAndLocationProps) => {
    const { trackEvent } = useContext(RouteContext)

    const { product_locations, product_locations_count } = variation
    const productLocation = product_locations[0]
    const { product, is_discount_price } = productLocation

    const handleClick = useCallback(() => {
        trackEvent({
            action: 'clickViewProduct',
            id: productLocation.location.id,
        })
    }, [productLocation.location.id, trackEvent])

    return (
        <li>
            <Link href={productLocationUrl({ product, location })}>
                <a onClick={handleClick}>
                    <ProductLocationItemImage
                        index={index}
                        image={product?.images?.[0]}
                        title={product?.title}
                        pricing={
                            (is_discount_price && {
                                price: productLocation.price,
                                original_price: productLocation.original_price,
                            }) ||
                            undefined
                        }
                        theme={theme}
                    />
                    <ProductLocationItemTitles
                        title={product.title}
                        subtitle={formatSubtitle(product)}
                        variationCount={
                            (product_locations_count > 1 &&
                                product_locations_count) ||
                            undefined
                        }
                    />

                    {!!productLocation.price && (
                        <div className="price-and-unit">
                            <PriceAndUnit {...productLocation} />
                        </div>
                    )}
                </a>
            </Link>
        </li>
    )
}

const useProductLocationModal = (
    productLocation: ProductLocation
): ((ev: React.MouseEvent<HTMLButtonElement>) => void) => {
    const { openModal } = useContext(ModalContext)
    const handleZoom = useCallback(
        (ev) => {
            ev.preventDefault()
            openModal({
                closeButton: true,
                render: (props) => (
                    <ProductLocationModal
                        {...props}
                        productLocation={productLocation}
                    />
                ),
            })
        },
        [openModal, productLocation]
    )
    return handleZoom
}

const useProductLocationTracker = (
    productLocation: ProductLocation
): ((ev: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void) => {
    const { trackEvent } = useContext(RouteContext)
    const handleClick = useCallback(() => {
        trackEvent({
            action: 'clickViewProduct',
            id: productLocation.location.id,
        })
    }, [productLocation.location.id, trackEvent])
    return handleClick
}

interface ProductLocationSingleProps {
    productLocation: ProductLocation
}

export const ProductLocationSingle = ({
    productLocation,
}: ProductLocationSingleProps) => {
    const { product, is_discount_price } = productLocation
    const openModal = useProductLocationModal(productLocation)

    return (
        <div className="location-product-item location-product-single-item">
            <ProductLocationItemImage
                index={0}
                image={product?.images?.[0]}
                title={product?.title}
                pricing={
                    (is_discount_price && {
                        price: productLocation.price,
                        original_price: productLocation.original_price,
                    }) ||
                    undefined
                }
            />
            <div>
                <ProductLocationItemTitles
                    link={productLocationUrl(productLocation)}
                    title={product.title}
                    subtitle={formatSubtitle(productLocation.product)}
                    variationCount={
                        (productLocation.product.product_locations_count > 1 &&
                            productLocation.product.product_locations_count) ||
                        undefined
                    }
                />
                <ReadMore
                    length={250}
                    text={
                        productLocation.description ||
                        productLocation.product.description
                    }
                    link={productLocationUrl(productLocation)}
                />
                {!!productLocation.price && (
                    <div className="price-and-unit">
                        <PriceAndUnit {...productLocation} />
                    </div>
                )}
                <button
                    type="button"
                    className="btn  btn-success"
                    onClick={openModal}
                >
                    <ShoppingListIcon />
                    <span>{t('Add to cart')}</span>
                </button>
            </div>
        </div>
    )
}

interface ProductLocationProps {
    productLocation: ProductLocation
    index: number
    showIndexBadge?: boolean
}

const ProductLocationItem = ({
    productLocation,
    index,
}: ProductLocationProps) => {
    const { product, is_discount_price } = productLocation

    const trackClick = useProductLocationTracker(productLocation)
    const openModal = useProductLocationModal(productLocation)

    return (
        <li className="location-product-item location-product-list-item">
            <Link href={productLocationUrl(productLocation)}>
                <a onClick={trackClick}>
                    <ProductLocationItemImage
                        image={product?.images?.[0]}
                        title={product?.title}
                        pricing={
                            (is_discount_price && {
                                price: productLocation.price,
                                original_price: productLocation.original_price,
                            }) ||
                            undefined
                        }
                        index={index}
                    />

                    <ProductLocationItemTitles
                        title={product.title}
                        subtitle={formatSubtitle(productLocation.product)}
                        variationCount={
                            (productLocation.product.product_locations_count >
                                1 &&
                                productLocation.product
                                    .product_locations_count) ||
                            undefined
                        }
                    />

                    {!!productLocation.price && (
                        <div className="price-and-unit">
                            <PriceAndUnit {...productLocation} />
                        </div>
                    )}
                </a>
            </Link>
            <button
                type="button"
                className="btn btn-success"
                onClick={openModal}
            >
                <ShoppingListIcon />
                <span>{t('Add to cart')}</span>
            </button>
        </li>
    )
}

export default ProductLocationItem
