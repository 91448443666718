import { t } from '@zupr/i18n'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'
import { Aggregations, Variables } from '@zupr/types/fo'
import { useRouter } from 'next/router'
import { useContext, useMemo } from 'react'

import RouteContext from '../../../../context/route'
import FilterBox from '../../../../shared/filters/box'
import SwitchField from '../../../../shared/form/fields/switch'

interface Props {
    aggregations: Aggregations
    variables: Variables
}

const DiscountFilter = ({ aggregations, variables }: Props) => {
    const { push } = useRouter()

    const { changeQuery } = useContext(RouteContext)

    const hasDiscountedProducts: boolean = useMemo(() => {
        return !!aggregations.data?.[aggregationKeys.discount]?.buckets?.[0]?.[
            `reverse.${aggregationKeys.discount}`
        ].doc_count
    }, [aggregations.data])

    const discountField = {
        name: 'discount',
        value: variables[filterKeys.discount] === 'true',
        setValue: () => {
            push(
                changeQuery({
                    discount: variables[filterKeys.discount] ? null : 'true',
                })
            )
        },
    }

    if (!hasDiscountedProducts && !variables[filterKeys.discount]) return null

    return (
        <FilterBox
            header={
                <>
                    <label htmlFor="discount">{t('Acties')}</label>
                    <SwitchField field={discountField} />
                </>
            }
        />
    )
}

export default DiscountFilter
