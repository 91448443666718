import { filterKeys } from '@zupr/next/helpers/products'
import { Variables } from '@zupr/types/fo'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import RouteContext from '../../../../context/route'
import { useShopperLocation } from '../../../../context/shopper'
import Trans from '../../../../shared/components/trans'
import FilterBox from '../../../../shared/filters/box'
import RemoveFilter from '../../../../shared/filters/remove'
import SwitchField from '../../../../shared/form/fields/switch'

interface Props {
    variables: Variables
}

export const RemoveShopperLocation = () => {
    const { distance, yourLocation } = useShopperLocation()
    return (
        <RemoveFilter filterKey="box" value="false">
            {yourLocation}
            {` (+${distance}km)`}
        </RemoveFilter>
    )
}

interface Props {
    variables: Variables
}

const ShopperLocation = ({ variables }: Props) => {
    const { push } = useRouter()

    const { changeQuery } = useContext(RouteContext)
    const { box } = useShopperLocation()

    const field = {
        name: 'box',
        value: !!variables[filterKeys.box],
        setValue: () => {
            push(
                changeQuery({
                    box: variables[filterKeys.box] ? 'false' : box,
                })
            )
        },
    }

    if (!box) return null

    return (
        <FilterBox
            header={
                <>
                    <label htmlFor="box">
                        <Trans label="Your location" />
                    </label>
                    <SwitchField field={field} />
                </>
            }
        />
    )
}

export default ShopperLocation
