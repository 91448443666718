import { t } from '@zupr/i18n'
import React, { useCallback, useState } from 'react'
import Highlighter from 'react-highlight-words'

import Checkbox from '../form/fields/checkbox'
import LinkChangeQuery, {
    LinkChangeQueryProps,
} from '../router/link-change-query'

interface Props extends LinkChangeQueryProps {
    name: string
    checked?: boolean
    count?: number
    title?: string
    search?: string
    children?: React.ReactNode
    showLoading?: boolean
    alt?: string
}

const SelectItem = ({
    children,
    count,
    name,
    checked,
    search,
    title,
    showLoading = true,
    alt,
    ...props
}: Props) => {
    const [loading, setLoading] = useState(false)

    const handleClick = useCallback(() => {
        if (!showLoading) return
        setLoading(true)
        setTimeout(() => setLoading(false), 500)
    }, [showLoading])

    return (
        <li>
            <LinkChangeQuery {...props} scroll={false} onClick={handleClick}>
                {!checked && loading && (
                    <span className="loading loading-checkbox" />
                )}

                {(!loading || checked) && (
                    <Checkbox
                        key={`${name}-${checked}`}
                        field={{ name, value: checked, setValue: handleClick }}
                    />
                )}

                <span>
                    {children}

                    {!search && t(title)}

                    {search && (
                        <Highlighter
                            autoEscape
                            searchWords={search?.split(' ') || []}
                            textToHighlight={t(title)}
                            highlightTag="strong"
                        />
                    )}
                    {!!alt && (
                        <>
                            {' '}
                            <small>{t(alt)}</small>
                        </>
                    )}
                    {!!count && (
                        <>
                            {' '}
                            <small>{`(${count})`}</small>
                        </>
                    )}
                </span>
            </LinkChangeQuery>
        </li>
    )
}

export default SelectItem
