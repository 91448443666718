import { Aggregations, Variables } from '@zupr/types/fo'
import { useMemo } from 'react'

import { aggregationKeys, filterKeys } from '@zupr/next/helpers/products'
import Trans from '../../../../shared/components/trans'
import FilterBox from '../../../../shared/filters/box'
import SelectItem from '../../../../shared/filters/select-item'

interface Props {
    aggregations: Aggregations
    variables: Variables
}

const FeatureFilters = ({ aggregations, variables }: Props) => {
    const features = useMemo(() => {
        const deliverableBucket = aggregations.data?.[
            aggregationKeys.deliverable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        const collectableBucket = aggregations.data?.[
            aggregationKeys.collectable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        const reservableBucket = aggregations.data?.[
            aggregationKeys.reservable
        ]?.buckets.find(({ key_as_string }) => key_as_string === 'true')

        return {
            deliverable:
                deliverableBucket?.[`reverse.${aggregationKeys.deliverable}`]
                    .doc_count,
            collectable:
                collectableBucket?.[`reverse.${aggregationKeys.collectable}`]
                    .doc_count,
            reservable:
                reservableBucket?.[`reverse.${aggregationKeys.reservable}`]
                    .doc_count,
        }
    }, [aggregations.data])

    if (!features.reservable && !features.collectable && !features.deliverable)
        return null

    return (
        <FilterBox header="Afhalen of bezorgen">
            <div className="multiselect-filter">
                <ul>
                    {(!!features.reservable ||
                        variables[filterKeys.reservable]) && (
                        <SelectItem
                            query={{
                                reservable: variables[filterKeys.reservable]
                                    ? null
                                    : 1,
                            }}
                            name="reservable"
                            checked={!!variables[filterKeys.reservable]}
                        >
                            <Trans label="Reserve" />
                        </SelectItem>
                    )}
                    {(!!features.collectable ||
                        variables[filterKeys.collectable]) && (
                        <SelectItem
                            query={{
                                collectable: variables[filterKeys.collectable]
                                    ? null
                                    : 1,
                            }}
                            name="collectable"
                            checked={!!variables[filterKeys.collectable]}
                        >
                            <Trans label="Click & Collect" />
                        </SelectItem>
                    )}
                    {(!!features.deliverable ||
                        variables[filterKeys.deliverable]) && (
                        <SelectItem
                            query={{
                                deliverable: variables[filterKeys.deliverable]
                                    ? null
                                    : true,
                            }}
                            name="deliverable"
                            checked={!!variables[filterKeys.deliverable]}
                        >
                            <Trans label="Deliver" />
                            {'*'}
                        </SelectItem>
                    )}
                </ul>
            </div>
            {(!!features.deliverable || variables[filterKeys.deliverable]) && (
                <small>
                    {'*'}
                    <Trans label="Afhankelijk van je postcode" />
                </small>
            )}
        </FilterBox>
    )
}

export default FeatureFilters
