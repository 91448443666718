import { t } from '@zupr/i18n'
import classnames from 'classnames'
import React from 'react'

import '../../../scss/react/filter/filters.scss'

export interface Props {
    header?: string | React.ReactNode
    children?: React.ReactNode
    className?: string
}

export const FilterBox = ({ children, header, className }: Props) => (
    <div className={classnames('filter-box', className)}>
        {!!header && (
            <div className="filter-box-header">
                {typeof header === 'string' && <h4>{t(header)}</h4>}
                {typeof header !== 'string' && header}
            </div>
        )}
        {children}
    </div>
)

export default FilterBox
