import { useEffect, useState } from 'react'

export const useMediaQuery = (query: string, initial: boolean) => {
    const [matches, setMatches] = useState(initial)

    useEffect(() => {
        const mediaQuery = window.matchMedia(query)

        setMatches(mediaQuery.matches)

        const handler = (event: MediaQueryListEvent) =>
            setMatches(event.matches)

        mediaQuery.addListener(handler)

        return () => mediaQuery.removeListener(handler)
    }, [query])

    return matches
}

export const useEscapeKey = (callback: () => void) => {
    useEffect(() => {
        const handleEscKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                callback()
            }
        }

        window.addEventListener('keydown', handleEscKey)

        return () => {
            window.removeEventListener('keydown', handleEscKey)
        }
    }, [callback])
}

// Custom hook to detect clicks outside of a given element
export const useClickOutside = (
    ref: React.RefObject<HTMLElement>,
    callback: () => void
) => {
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                callback() // Call the callback if click is outside the element
            }
        }

        // Add event listener to handle outside clicks
        document.addEventListener('mousedown', handleClickOutside)

        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}
