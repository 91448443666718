import { t } from '@zupr/i18n'
import classNames from 'classnames'
import React from 'react'

import Question from '../../../../svg/question.svg'

import Tooltip from '../../components/tooltip'

import '../../../../scss/react/product/data.scss'

export interface Spec {
    value: string
    label: string
    help?: string
    className?: string
}

interface SpecsProps {
    specs: Spec[]
}

const Specs = ({ specs }: SpecsProps) => {
    if (!specs?.length) return null
    return (
        <div className="product-data-grid">
            {specs.map(({ value, label, help, className }) => (
                <React.Fragment key={label}>
                    <div className={classNames(className)}>
                        <strong>
                            {t(label)}
                            {!!help && (
                                <Tooltip label={help}>
                                    <Question />
                                </Tooltip>
                            )}
                        </strong>
                    </div>
                    <div>
                        <p>{value}</p>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

interface ProductDataProps {
    specs?: Spec[]
    groups?: {
        title: string
        specs: Spec[]
    }[]
}

const ProductData = ({ specs, groups }: ProductDataProps) => (
    <div className="product-data">
        {!!specs && <Specs specs={specs} />}
        {!!groups &&
            groups.map(({ title, specs }) => {
                if (!specs || !specs.length) return null
                return (
                    <React.Fragment key={title}>
                        <h5>{t(title)}</h5>
                        <Specs specs={specs} />
                    </React.Fragment>
                )
            })}
    </div>
)

export const useCodes = ({ id, isbn, barcode, gpc, item_code }) => {
    const specs = []

    if (id) {
        specs.push({
            value: id,
            label: 'product_location.product.code.zupr_id',
            help: 'product_location.product.code.zupr_id.help',
        })
    }
    if (isbn) {
        specs.push({
            value: isbn,
            label: 'ISBN',
        })
    }
    if (barcode && !isbn) {
        specs.push({
            value: barcode,
            label: 'product_location.product.code.ean',
        })
    }

    if (gpc) {
        specs.push({
            value: gpc,
            label: 'product_location.product.code.gpc',
            help: 'product_location.product.code.gpc.help',
        })
    }

    if (item_code) {
        specs.push({
            value: item_code,
            label: 'product_location.product.code.item_code',
            help: 'product_location.product.code.item_code.help',
        })
    }

    return specs
}

export default ProductData
